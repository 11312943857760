<template lang="pug">
v-dialog(v-model="opened", width="500")
  v-flex.vitrify(xs12, v-if="opened" style="min-height: 10vh;  background: rgba(255,255,255,0.8)")
    div.expande-horizontal.wrap.pt-0
      div.expande-horizontal.windows-style.fonte
        v-flex(xs12)
          v-list.px-0.mx-0(nav, dense, color="transparent")
            v-list-item 
              v-avatar.mr-3(color="#333")
                v-icon(:color="$theme.primary") mdi-map-search-outline
              v-list-item-content
                v-list-item-title.fonte.text-xs-center
                  span.fonte Endereço
                v-list-item-subtitle.fonte
                  span.fonte Busque o endereço pelo CEP
              v-list-item-action
                v-avatar(size="31",:color="$theme.primary")
                  v-btn(
                    @click="close"
                    color="#333"
                    text
                    small
                    fonte
                  )
                    v-icon mdi-close
      v-flex(xs12).pa-6.pt-6
        div.card-method-form.expande-horizontal.wrap.fonte
          v-flex(xs12)
            v-form(ref="payer_form")
              v-flex(xs7)
                v-text-field(
                  dense
                  label="CEP",
                  v-model="address.zip_code"
                  filled
                  :color="$theme.primary"
                  ref="cepInput"
                  @input="searchCep"
                  :rules="cepRules"
                  v-mask="['########']"
                  placeholder="########"
                ) 
              div.expande-horizontal
                v-flex(xs9).pr-3
                  v-text-field(
                    dense
                    v-model="address.street"
                    label="Endereço",
                    filled
                    :color="$theme.primary"
                  ) 
                v-flex(xs3)
                  v-text-field(
                    dense
                    v-model="address.number"
                    label="Nº",
                    ref="addressNumber"
                    @keyup.enter.prevent="saveAddress"
                    filled
                    :color="$theme.primary"
                  ) 
              v-flex(xs12)
                div.expande-horizontal
                  v-flex.mr-3(xs4)
                    v-text-field(
                      v-model="address.city"
                      filled
                      :color="$theme.primary"
                      dense,
                      label="Cidade",
                      placeholder="ex: Macapá"
                    ) 
                  v-flex.mr-3(xs4)
                    v-text-field(
                      v-model="address.state"
                      filled
                      :color="$theme.primary"
                      dense,
                      label="Estado",
                      placeholder="ex: Amapá"
                    ) 
                  v-flex(xs6)
                    v-text-field(
                      v-model="address.district"
                      filled
                      :color="$theme.primary"
                      dense,
                      label="Bairro",
                      placeholder="ex: Zerão"
                    ) 
          v-flex(xs12)
            v-btn.fonte.black--text.elevation-0(rounded,@click="saveAddress",:color="$theme.primary") Salvar        

</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      cepRules: [v => !!v || "preencha seu cep"],
      opened: false,
      address: {}
    };
  },
  methods: {
    ...mapActions(["get_cep"]),
    async searchCep() {
      if (this.address.zip_code && this.address.zip_code.length === 8) {
        const res = await axios.get(
          `https://viacep.com.br/ws/${this.address.zip_code}/json/`
        );
        if (res.status === 200) {
          this.address.street = res.data.logradouro;
          this.address.district = res.data.bairro;
          this.address.city = res.data.localidade;
          this.address.state = res.data.uf;
          this.address.bairro = res.data.bairro;
          this.address.range_numbers = res.data.complemento;
          this.address.number = "";
          this.$refs.addressNumber.focus();
          this.$forceUpdate();
        }
      }
    },
    saveAddress() {
      if (!this.address.number) {
        this.$store.dispatch("createGlobalMessage", {
          type: "error",
          message: "Preencha o número do endereço",
          timeout: 4000
        })
        return;
      }
      if (this.$refs.payer_form.validate()) {
        this.$emit("input", this.address);
        setTimeout(() => {
          this.close();
        }, 300);
      }
    },
    open() {
      this.address = this.value || {};
      this.opened = true;
      setTimeout(() => {
        this.$refs.cepInput.focus();
      }, 300);
    },
    close() {
      this.opened = false;
      setTimeout(() => {
        this.$forceUpdate();
      }, 1000);
    }
  }
};
</script>

<style scoped>
.card-method-card {
  max-width: 300px;
}
.card-title {
  color: #363636;
}
.card-container {
  width: 100%;
  display: flex;
  background: #f0f0f0;
  border: 1px solid #0da8f0;
  border-radius: 10px;
}
</style>
