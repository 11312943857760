<template>
  <v-dialog width="480" v-model="opened" transition="slide-x-transition">
    <div
      style="border-radius: 20px !important;"
      class="expande-horizontal column pt-0"
    >
      <v-flex xs12>
        <div class="windows-style expande-horizontal fonte column">
          <transition name="slide-fade">
            <v-list-item class="pa-0 ma-0 pl-2">
              <v-list-item-content>
                <v-list-item-title class="">
                  Agendamento
                </v-list-item-title>
                <v-list-item-subtitle class="font-mini">
                  Gerencie os detalhes abaixo
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <div class="expande-horizontal centraliza">
                  <!-- <v-btn
                    color="red"
                    @click="cancel"
                    small
                    rounded
                    v-if="payload._id"
                    class="elevation-0 mr-1 fonte white--text text-lowercase"
                    >Cancelar</v-btn
                  > -->
                  <v-avatar
                    @click="close"
                    style="cursor: pointer;"
                    size="27"
                    :color="$theme.primary"
                    class="mr-2"
                    icon
                  >
                    <v-icon size="17" color="#f2f2f2">mdi-close</v-icon>
                  </v-avatar>
                </div>
              </v-list-item-action>
            </v-list-item>
          </transition>
        </div>
      </v-flex>
      <div
        :style="
          `${
            $vuetify.breakpoint.lgAndUp
              ? 'max-height: 70vh; overflow: auto;'
              : ''
          }`
        "
        class="windows-style-content expande-horizontal wrap"
      >
        <v-flex xs12>
          <v-flex v-if="!payload.type" class="px-2 pt-3 mb-2" xs12>
            <div
              style="min-height: 40vh;"
              class="expande-horizontal centraliza wrap"
            >
              <div class="expande-horizontal centraliza wrap">
                <v-btn
                  rounded
                  small
                  @click="selectType('visit')"
                  class="elevation-0 ma-2 black--text"
                  :color="$theme.primary"
                  >Visita</v-btn
                >
                <v-btn
                  rounded
                  small
                  @click="selectType('limit_date')"
                  class="elevation-0 ma-2 black--text"
                  :color="$theme.primary"
                  >Data Limite</v-btn
                >
                <v-btn
                  rounded
                  small
                  @click="selectType('delivery')"
                  class="elevation-0 ma-2 black--text"
                  :color="$theme.primary"
                  >Entrega</v-btn
                >
                <v-btn
                  rounded
                  small
                  @click="selectType('ligação')"
                  class="elevation-0 ma-2 black--text"
                  :color="$theme.primary"
                  >Ligação</v-btn
                >
                <v-btn
                  rounded
                  small
                  @click="selectType('email')"
                  class="elevation-0 ma-2 black--text"
                  :color="$theme.primary"
                  >E-mail</v-btn
                >
                <v-btn
                  rounded
                  small
                  @click="selectType('whatsapp')"
                  class="elevation-0 ma-2 black--text"
                  :color="$theme.primary"
                  >Whatsapp</v-btn
                >
                <v-btn
                  rounded
                  small
                  @click="selectType('other')"
                  class="elevation-0 ma-2 black--text"
                  :color="$theme.primary"
                  >Outros</v-btn
                >
              </div>
            </div>
          </v-flex>
          <v-form v-if="payload.type" ref="form">
            <v-flex class="px-2 mt-2 mb-2" xs12>
              <div
                @click="$refs.deliveryInput.open(payload)"
                class="fonte"
                style="cursor: pointer;"
              >
                <div
                  v-if="
                    payload.address_destiny && payload.address_destiny.number
                  "
                >
                  <v-icon :color="$theme.primary">
                    mdi-map-marker-path
                  </v-icon>
                  {{ payload.address_destiny.street }},
                  {{ payload.address_destiny.bairro }} Nº
                  {{ payload.address_destiny.number }}
                </div>
                <div v-else>
                  <v-icon size="15" color="red">
                    mdi-map-marker
                  </v-icon>
                  <span :style="`color: red`" class="fonte">
                    clique aqui para informar um endereço
                  </span>
                </div>
              </div>
            </v-flex>
            <v-flex class="px-2 pt-3" xs12>
              <div class="expande-horizontal centraliza">
                <v-autocomplete
                  v-model="payload.type"
                  label="Tipo de agendamento"
                  placeholder="ex: Visita"
                  item-text="name"
                  filled
                  hide-details
                  item-value="value"
                  :rules="[v => !!v || 'Preencha este campo']"
                  :items="types"
                  dense
                ></v-autocomplete>
              </div>
            </v-flex>
            <v-flex class="px-2 pt-3 mb-2" xs12>
              <div class="expande-horizontal centraliza">
                <v-autocomplete
                  v-model="payload.executor"
                  label="Usuário Responsável"
                  placeholder="ex: Sara"
                  item-text="nome"
                  filled
                  hide-details
                  item-value="_id"
                  :rules="[v => !!v || 'Preencha este campo']"
                  :items="get_funcionarios.docs"
                  dense
                ></v-autocomplete>
              </div>
            </v-flex>
            <!-- <v-flex class="px-2" xs12>
              <div class="expande-horizontal centraliza">
                <v-time-picker
                  format="24hr"
                  full-width
                  dark
                  no-title
                  color="#333"
                  v-model="payload.visit_hour"
                ></v-time-picker>
              </div>
            </v-flex> -->
            <v-flex class="px-2" xs12>
              <v-autocomplete
                class="fonte"
                dense
                label="Cliente"
                :items="get_clientes.docs"
                item-text="nome"
                return-object
                hide-details
                filled
                @click:prepend-inner="abre_modal_view_cliente"
                prepend-inner-icon="mdi-account-plus-outline"
                :rules="[v => !!v || 'Preencha este campo']"
                v-model="payload.client"
              ></v-autocomplete>
            </v-flex>

            <v-flex xs12>
              <div class="pa-2 expande-horizontal centraliza">
                <v-textarea
                  v-model="payload.name"
                  dense
                  filled
                  hide-details
                  label="Descrição"
                  :color="$theme.primary"
                  :rules="[v => !!v || 'Preencha este campo']"
                ></v-textarea>
              </div>
            </v-flex>

            <div class="expande-horizontal">
              <v-flex class="px-2 mb-2" xs6>
                <div class="expande-horizontal centraliza">
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="payload.visit_date"
                        label="Data do agendamento"
                        hide-details
                        v-bind="attrs"
                        filled
                        :rules="[v => !!v || 'Preencha este campo']"
                        :color="$theme.primary"
                        @blur="parseDate(payload.visit_date)"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateUnformatted"
                      no-title
                      :color="$theme.primary"
                      @input="formatDate(dateUnformatted)"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </v-flex>
              <v-flex class="px-2" xs6>
                <v-text-field
                  v-model="payload.visit_hour"
                  filled
                  hide-details
                  label="Hora"
                  v-mask="['##:##']"
                  :color="$theme.primary"
                  :rules="[
                    v => !!v || 'Preencha este campo',
                    v => (v && v.length === 5) || 'Preencha este campo'
                  ]"
                ></v-text-field>
              </v-flex>
            </div>
          </v-form>
        </v-flex>
      </div>
      <v-flex style="background-color: #f2f2f2;" class="pa-2" xs12>
        <div
          v-if="
            payload.type &&
              (!!payload.address_destiny ||
                payload.type !== 'delivery' ||
                payload.type !== 'visit') &&
              !!payload.executor
          "
          class="expande-horizontal centraliza"
        >
          <v-spacer></v-spacer>
          <v-btn
            v-if="!payload._id"
            dark
            @click="valida_form"
            :color="$theme.primary"
          >
            <span class="fonte black--text"> Agendar </span>
          </v-btn>
          <v-btn v-else dark @click="valida_form" :color="$theme.primary">
            <span class="fonte white--text"> Atualizar agendamento </span>
          </v-btn>
        </div>
      </v-flex>
    </div>
    <DeliveryInput
      ref="deliveryInput"
      @input="setAddressDestiny($event)"
      v-model="payload.address_destiny"
    />
    <ModalCreateClient />
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import DeliveryInput from "./DeliveryInput.vue";
import ModalCreateClient from "@/apps/44#Clientes/components/modalView.vue";

export default {
  props: ["origin"],
  computed: {
    ...mapGetters(["get_clientes", "getLoggedUser", "get_funcionarios"])
  },
  data() {
    return {
      opened: false,
      payload: {
        visit_date: null,
        type: "",
        address_destiny: null,
        visit_hour: "",
        executor: null,
      },
      from: "",
      dateUnformatted: null,
      menu: false,
      types: [
        {
          name: "Visita",
          value: "visit"
        },
        {
          name: "Data limite",
          value: "limit_date"
        },
        {
          name: "Entrega",
          value: "delivery"
        },
        {
          name: "Ligação",
          value: "ligação"
        },
        {
          name: "E-mail",
          value: "email"
        },
        {
          name: "Whatsapp",
          value: "whatsapp"
        },
        {
          name: "Outros",
          value: "other"
        }
      ]
    };
  },
  components: {
    DeliveryInput,
    ModalCreateClient
  },
  methods: {
    ...mapActions([
      "abre_modal_view_cliente",
      "listar_external_movimentations",
      "fecha_modal_view_gestordepedido",
      "abre_modal_view_gestordepedido"
    ]),
    selectType(type) {
      const p = this.payload;
      this.payload = {
        ...p,
        type
      };
    },
    setAddressDestiny(address) {
      const p = this.payload;
      this.payload = {
        ...p,
        address_destiny: address
      };
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      this.payload.visit_date = `${day}/${month}/${year}`;
      this.menu = false;
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      this.dateUnformatted = `${year}-${month.padStart(2, "0")}-${day.padStart(
        2,
        "0"
      )}`;
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    valida_form() {
      if (this.payload.type === "delivery" && !this.payload.address_destiny.zip_code) {
        this.$store.dispatch("createGlobalMessage", {
          type: "warning",
          message: "Informe o endereço de entrega",
          timeout: 4000
        });
        return;
      }
      if (this.$refs.form.validate()) {
        this.opened = false;
        let use_case = "";
        if (this.payload._id) {
          use_case = "external_movimentations/update-external-movimentation";
        }
        if (this.from === "sale" && !this.payload._id) {
          use_case = "external_movimentations/create-from-transaction";
        }
        if (this.from !== "sale" && !this.payload._id) {
          use_case = "external_movimentations/create-from-calendar";
        }
        if (this.payload._id) {
          this.$run(use_case, this.payload)
            .then((res) => {
              this.$store.dispatch("createGlobalMessage", {
                type: "success",
                message: "O agendamento foi atualizado 😎",
                timeout: 2000
              });
              if (this.origin && this.origin === "sale-body") {
                const sale_updated = {
                  ...this.$store.getters.get_gestordepedido,
                  external_movimentation: res.data
                }
                if (this.$route.name == 'Gestor de Vendas') {
                  this.fecha_modal_view_gestordepedido()
                  setTimeout(() => {
                    this.abre_modal_view_gestordepedido(sale_updated)
                  },300)
                }
              }
              this.listar_external_movimentations();
              this.close();
            })
            .catch(e => {
              console.log("e dfdfdf", e);
              this.$store.dispatch("createGlobalMessage", {
                type: "warning",
                message: e.response.data.message,
                timeout: 4000
              });
              this.opened = true;
            });
        } else {
          this.$run(use_case, this.payload)
            .then((res) => {
              this.$store.dispatch("createGlobalMessage", {
                type: "success",
                message: "Agendado 🚀",
                timeout: 2000
              });
              if (this.origin && this.origin === "sale-body") {
                const sale_updated = {
                  ...this.$store.getters.get_gestordepedido,
                  external_movimentation: res.data
                }
                if (this.$route.name == 'Gestor de Vendas') {
                  this.fecha_modal_view_gestordepedido()
                  setTimeout(() => {
                    this.abre_modal_view_gestordepedido(sale_updated)
                  },300)
                }
              }
              this.listar_external_movimentations();
              this.close();
            })
            .catch(e => {
              this.$store.dispatch("createGlobalMessage", {
                type: "warning",
                message: e.response.data.message,
                timeout: 2000
              });
              this.opened = true;
            });
        }
      }
    },
    open(payload, from) {
      if (from === "sale") {
        this.payload = {
          transaction: payload._id,
          client: payload.compra.cliente,
          address_destiny: payload.compra.address || {}
        };
      }
      if (from === "open-event") {
        this.payload = payload;
      }
      if (from === "create-event") {
        this.payload = {
          visit_date: this.formatDate(payload.date)
        };
        this.dateUnformatted = payload.date;
      }
      if (!from && payload) {
        this.payload = payload;
      }
      if (!from && !payload) {
        this.payload = {};
      }
      this.from = from;
      this.opened = true;
    },
    close() {
      this.opened = false;
      this.payload = {
        visit_date: null,
        type: "",
        address_destiny: null,
        visit_hour: "",
        executor: null,
      };
    }
  }
};
</script>
